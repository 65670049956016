import { QueryParams, Task } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'tarefas';

export default {
  all: async (params?: QueryParams) => getAll<Task>({ endpoint, params }),
  listAll: async () => listAll<Task>({ endpoint }),
  one: async (id: string) => getOne<Task>({ endpoint, id }),
  destroy: async (id: string) => deleteOne({ endpoint, id }),
  create: async (formData: Partial<Task>) => create<Task>({ endpoint, formData }),
  update: async (id: string, formData: Partial<Task>) => update<Task>({ endpoint, formData, id }),
  async getDashboard(params?: any){
    console.log(params);
    try {
      const where = params?.where ? '&' + params?.where : '';
      const { data } = await api.get(`${endpoint}?relations=produtos,conferente${where}`);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },
};
