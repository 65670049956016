import PageContainer from '../../../components/container/PageContainer';
import React, {useEffect, useState} from 'react';
import Title from '../../../components/text/Title';
import PageCard from '../../../components/card/PageCard';
import departamentoApi from '../../../services/departamentoApi';
import Alert from '../../../helpers/Alert';
import ReactSelect from '../../../components/select/ReactSelect';
import sectionApi from '../../../services/sectionApi';
import localizacaoApi from '../../../services/localizacaoApi';
import lojaApi from '../../../services/lojaApi';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import usuarioApi from '../../../services/usuarioApi';
import produtoApi from '../../../services/produtoApi';
import Checkbox from '../../../components/checkbox/Checkbox';
import tipoLeituraApi from '../../../services/tipoLeituraApi';
import taskApi from '../../../services/taskApi';

export default function Task() {
  const [departamentosOptions, setDepartamentosOptions] = useState<any>([]);
  const [departamentosSelected, setDepartamentosSelected] = useState<any>([]);

  const [secoesOptions, setSecoesOptions] = useState<any>([]);
  const [secoesSelected, setSecoesSelected] = useState<any>([]);

  const [lojaOptions, setLojaOptions] = useState<any>([]);
  const [lojaSelected, setLojaSelected] = useState<any>('');

  const [localizacaoOptions, setLocalizacaoOptions] = useState<any>([]);
  const [localizacaoSelected, setLocalizacaoSelected] = useState<any>([]);

  const [usuariosOptions, setUsuariosOptions] = useState<any>([]);
  const [usuariosSelected, setUsuariosSelected] = useState<any>('');

  const [tipoPesquisaOptions, setTipoPesquisaOptions] = useState<any>([]);
  const [tipoPesquisaSelected, setTipoPesquisaSelected] = useState<any>('');

  const [filtrosDataSelected, setFiltrosDataSelected] = useState<any>('');

  const [diasSemVendas, setDiasSemVendas] = useState('');
  const [vencimentoEmXDias, setVencimentoEmXDias] = useState('');

  const [produtoRecebidoDiaX, setProdutoRecebidoDiaX] = useState('');

  const [produtos, setProdutos] = useState<any>([]);

  const [inputFilter, setInputFilter] = useState<any>();

  const [produtoSelecionados, setProdutoSelecionados] = useState([])
  const [produtoAdicionados, setProdutoAdicionados] = useState([])
  const [produtoSelecionadosRemover, setProdutoSelecionadosRemover] = useState([])

  const [state, setState] = useState({
    name: '',
    data: '',
  });


  const [loading, setLoading] = useState(true);

  const getData = async () => {
    Alert.await(`Carregando dados...`);
    const [/*departamentosData,*/ sessoesData, localizacoesData, lojaData, usuariosData, tipoPesquisaData] = await Promise.all([
      /*departamentoApi.listAll(),*/
      sectionApi.listAll(),
      localizacaoApi.listAll(),
      lojaApi.listAll(),
      usuarioApi.listAll(),
      /*tipo.listAll(),*/
      tipoLeituraApi.listAll()
    ]);

    /*if (!departamentosData.isError){
      let dtDepartamentoSelect = departamentosData.data.map((res: any) => ({label:res.nome, value: res.id}));
      dtDepartamentoSelect.unshift({label:'Todos', value: ''});
      setDepartamentosOptions(dtDepartamentoSelect as any);
    }*/

    if (!sessoesData.isError){
      let dtSessoesSelect = sessoesData.data.map((res: any) => ({label:res.nome, value: res.id}));
      dtSessoesSelect.unshift({label:'Todos', value: ''});
      setSecoesOptions(dtSessoesSelect as any);
    }

    if (!lojaData.isError){
      let dtLojasSelect = lojaData.data.map((res: any) => ({label:res.razao_social, value: res.id}));
      setLojaOptions(dtLojasSelect as any);
    }

    if (!localizacoesData.isError){
      let dtLocalizacaoSelect = localizacoesData.data.map((res: any) => ({label:res.descricao, value: res.id}));
      dtLocalizacaoSelect.unshift({label:'Todos', value: ''});
      setLocalizacaoOptions(dtLocalizacaoSelect as any);
    }

    if (!usuariosData.isError){
      let dtUsuarioSelect = usuariosData.data.map((res: any) => ({label:res.nome, value: res.id}));
      setUsuariosOptions(dtUsuarioSelect as any);
    }

    if (!tipoPesquisaData.isError){
      let dtTipoPesquisaSelect = tipoPesquisaData.data.map((res: any) => ({label:res.descricao, value: res.id}));
      setTipoPesquisaOptions(dtTipoPesquisaSelect as any);
    }

    setLoading(false)
    Alert.close();
  }

  useEffect(() => {
    getData()
  }, [])

  const optionsDates = [
    {label: 'Dias sem vendas a X dias', value: 'vendas'},
    {label: 'Produto com vencimento em X dias', value: 'vencimento'},
    {label: 'Produto com alteração de preços', value: 'preco'},
    {label: 'Produto recebidos', value: 'recebidos'},
  ];

  const onSelectOptionsFilter = (value: any) => {
    setFiltrosDataSelected(value)

    setDiasSemVendas('')
    setVencimentoEmXDias('')
    setProdutoRecebidoDiaX('')

  }

  const onClickProdutos = async () => {

    let filterData = '';

    if (filtrosDataSelected){
      filterData = `${filtrosDataSelected.value}`


      if (filtrosDataSelected.value === 'vendas'){
        if (diasSemVendas) {
          filterData += '/' + diasSemVendas
        } else {
          Alert.error('Digite a quantidade de dias')
          return;
        }
      }

      if (filtrosDataSelected.value === 'vencimento'){
        if (vencimentoEmXDias) {
          filterData += '/' + vencimentoEmXDias
        } else {
          Alert.error('Digite a quantidade de dias')
          return;
        }
      }

      if (filtrosDataSelected.value === 'recebidos'){
        if (produtoRecebidoDiaX) {
          filterData += '/' + produtoRecebidoDiaX
        } else {
          Alert.error('Digite a data')
          return;
        }
      }

    }else{
      Alert.error('Selecione o tipo de tarefas')
      return;
    }



    const loja = lojaSelected.value ? lojaSelected.value : '*' ;
    let localizacaoData: any = [];

    localizacaoSelected.map((localizacao: any) => {

      localizacaoData.unshift(localizacao.value)
    })
    if (localizacaoData.includes('') || !localizacaoData.length){
      localizacaoData = '*';
    }

    let sessoes: any = []

    secoesSelected.map((sessao: any) => {
      sessoes.unshift(sessao.value)
    })
    if (sessoes.includes('') || !sessoes.length){
      sessoes = '*';
    }






    const {data, isError} = await produtoApi.getByFiltro(sessoes, localizacaoData, loja, filterData);

    if (!isError){
      setProdutos(data);
    }
  }

  const handleChange = (value, input) => {
    setState({...state, [input]: value});
  };

  const onCheckProduto = (v, produto_id) => {
    let prod = [...produtoSelecionados];
    // @ts-ignore
    if (prod.includes(produto_id)){
      prod = prod.filter((id) => id !== produto_id)
    }else{
      // @ts-ignore
      prod.unshift(produto_id);
    }
    setProdutoSelecionados(prod);

  }
  const onCheckProdutoRemove = (v, produto_id) => {
    let prod = [...produtoSelecionadosRemover];
    // @ts-ignore
    if (prod.includes(produto_id)){
      prod = prod.filter((id) => produto_id !== produto_id)
    }else{
      // @ts-ignore
      prod.unshift(produto_id);
    }

    setProdutoSelecionadosRemover(prod);
  }

  const addProduto = () => {
    const newProdutoAdicionados =  [...produtoAdicionados]
    setProdutoAdicionados(newProdutoAdicionados.concat([...produtoSelecionados]))
    setProdutoSelecionadosRemover([]);
    setProdutoSelecionados([]);
  }

  const removeProduto = () => {

    let array = []
    produtoAdicionados.map((produtoAdicionar: any) => {
      // @ts-ignore
      if (!produtoSelecionadosRemover.includes(produtoAdicionar)){
        // @ts-ignore
        array.unshift(produtoAdicionar)
      }

    })

    setProdutoAdicionados(array)

  }

  const onSaveTask = async () => {

    Alert.await('Registrando Tarefa');
    const formData = {
      nome: state.name,
      conferente_id: usuariosSelected.value,
      tipo_id: tipoPesquisaSelected.value,
      data: state.data,
      produtos: produtoAdicionados
    }

    const {data, isError} = await taskApi.create(formData);

    Alert.close();

    if (isError) return Alert.error(data.message ? data.message : data);

    setState({
      name: '',
      data: '',
    });
    setProdutoAdicionados([]);
    setUsuariosSelected([]);
    setTipoPesquisaSelected([]);
    Alert.success("Tarefa criada com sucesso!");
  }

  return(
    <PageContainer menuTitle="Tarefas" pageTitle="Tarefas">
      <PageCard
        header={
          <div className="flex items-center justify-between">
            <Title color="primary">
              Tarefas
            </Title>
          </div>
        }
        footer={
          <div className="flex items-center gap-5">
            <Button  onClick={() => onSaveTask()}>
              Salvar
            </Button>
          </div>
        }
      >
        {
          loading ?
            <span className="flex items-center justify-center h-7">
              <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </span>
            :
            <>
              <div className="grid grid-cols-12 gap-5 pb-7">
                <ReactSelect
                  label="Tipo de tarefas"
                  required={false}
                  className="col-span-3"
                  options={optionsDates}
                  value={filtrosDataSelected}
                  setValue={v => onSelectOptionsFilter(v)}
                />
                {
                  filtrosDataSelected.value === 'vendas' ?
                    <Input
                      className={'col-span-3'}
                      label={'Quantidade de dias'}
                      value={diasSemVendas}
                      type={'number'}
                      setValue={(v) => setDiasSemVendas(v)}
                    />
                    :
                    ''
                }
                {
                  filtrosDataSelected.value === 'vencimento' ?
                    <Input
                      className={'col-span-3'}
                      label={'Quantidade de dias'}
                      value={vencimentoEmXDias}
                      type={'number'}
                      setValue={(v) => setVencimentoEmXDias(v)}
                    />
                    :
                    ''
                }
                {
                  filtrosDataSelected.value === 'recebidos' ?
                    <Input
                      className={'col-span-3'}
                      label={'Data do recebimento:'}
                      type={'date'}
                      value={produtoRecebidoDiaX}
                      setValue={(v) => setProdutoRecebidoDiaX(v)}
                    />
                    :
                    ''
                }
              </div>
              <div className="grid grid-cols-12 gap-5 pb-7">
                <ReactSelect
                  label="Lojas"
                  required={false}
                  className="col-span-3"
                  options={lojaOptions}
                  value={lojaSelected}
                  setValue={v => {
                    setLojaSelected(v);
                  }}
                  isMult={false}
                />
                {/*<ReactSelect
                  label="Departamentos"
                  required={false}
                  className="col-span-3"
                  options={departamentosOptions}
                  value={departamentosSelected}
                  setValue={v => {
                    setDepartamentosSelected(v);
                  }}
                  isMult={true}
                />*/}
                <ReactSelect
                  label="Localização"
                  required={false}
                  className="col-span-3"
                  options={localizacaoOptions}
                  value={localizacaoSelected}
                  setValue={v => {
                    setLocalizacaoSelected(v);
                  }}
                  isMult={true}
                />
                <ReactSelect
                  label="Sessões"
                  required={false}
                  className="col-span-4"
                  options={secoesOptions}
                  value={secoesSelected}
                  setValue={v => {
                    setSecoesSelected(v);
                  }}
                  isMult={true}
                />
                <Button
                  onClick={onClickProdutos}
                  className="col-span-1 mt-[1.5rem] min-w-[147px] h-[37.5px] "
                >
                  Buscar produtos
                </Button>
              </div>

              <div className={'flex w-full justify-end'}>

              </div>
              <div className="grid grid-cols-12 gap-5 pb-7">
                <Input
                  className={'col-span-5'}
                  label={'Nome da tarefa'}
                  value={state.name}
                  setValue={v => handleChange(v, 'name')}
                />
                <ReactSelect
                  label="Tipo de pesquisas"
                  required={false}
                  className="col-span-3"
                  options={tipoPesquisaOptions}
                  value={tipoPesquisaSelected}
                  setValue={v => setTipoPesquisaSelected(v)}
                />
              </div>
              <div className={'flex w-full'}>
                <div className={'w-[45%] h-[350px] border overflow-auto scroll-auto rounded-xl p-5'}>
                  {
                    produtos.length?

                      produtos?.map((produto: any) => {
                        // @ts-ignore
                        if (!produtoAdicionados.includes(produto.produto_id)){
                          // @ts-ignore
                          const value = produtoSelecionados.includes(produto.produto_id);
                          return(
                            <div className={'py-4'}>
                              <Checkbox
                                label={produto.descricao}
                                value={value}
                                setValue={(v) => onCheckProduto(v, produto.produto_id)}
                              />
                            </div>
                          )
                        }

                      })
                    :
                    ''

                  }


                </div>
                <div className={'w-[10%] flex flex-col items-center justify-center gap-5'}>
                  <Button className={'h-[50px]'} onClick={addProduto}> {'>>>'} </Button>
                  <Button className={'h-[50px]'} onClick={removeProduto}> {'<<<'} </Button>
                </div>
                <div className={'w-[45%] h-[350px] border overflow-auto scroll-auto scroll-auto p-5'}>
                  {
                    produtos.map((produto: any) => {
                      // @ts-ignore
                      if (produtoAdicionados.includes(produto.produto_id)){
                        // @ts-ignore
                        const value = produtoSelecionadosRemover.includes(produto.produto_id);
                        return(
                          <div className={'py-4'}>
                            <Checkbox
                              label={produto.descricao}
                              value={value}
                              setValue={(v) => onCheckProdutoRemove(v, produto.produto_id)}
                            />
                          </div>
                        )
                      }

                    })
                  }
                </div>
              </div>
              <div className="grid grid-cols-12 gap-5 pb-7 mt-4">
                <ReactSelect
                  label="Atribuir tarefa para:"
                  required={false}
                  className="col-span-5"
                  options={usuariosOptions}
                  value={usuariosSelected}
                  setValue={v => {
                    setUsuariosSelected(v);
                  }}
                  isMult={false}
                />
                <Input
                  className={'col-span-2'}
                  label={'Data para executar a tarefa'}
                  type={'date'}
                  value={state.data}
                  setValue={v => handleChange(v, 'data')}
                />
              </div>

            </>


        }


      </PageCard>
    </PageContainer>
  )
}