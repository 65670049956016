import api from './api';
//const endpoint = 'dashboard';

export default {
  async dashboardQuantityCount(
    idStore: string | number | undefined,
    initialDate: string,
    finalDate: string,
    departamento: any,
  ) {
    try {
      let url = `dashboard/contagemQuantidade?data_inicial=${initialDate}&data_final=${finalDate}&departamento=${departamento && departamento !== 'todos' ? departamento : ''}&id_loja=${idStore && idStore !== 'todos' ? idStore : ''}`;
      const {data} = await api.get(url);
      return {data, isError: false};
    } catch (err: any) {
      return {
        data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
        isError: true
      };
    }
  },
  async dashboardLocalCount(
    idStore: string | number | undefined,
    initialDate: string,
    finalDate: string,
    local: any,
  ) {
    try {
      let url = `dashboard/contagemLocal?data_inicial=${initialDate}&data_final=${finalDate}&local=${local && local !== 'todos' ? local : ''}&id_loja=${idStore && idStore !== 'todos' ? idStore : ''}`;
      const {data} = await api.get(url);
      return {data, isError: false};
    } catch (err: any) {
      return {
        data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
        isError: true
      };
    }
  },
  async dashboardVencimentoCount(
    idStore: string | number | undefined,
    initialDate: string,
    finalDate: string,
    local: any,
  ) {
    try {
      let url = `dashboard/vencimento?data_inicial=${initialDate}&data_final=${finalDate}&local=${local && local !== 'todos' ? local : ''}&id_loja=${idStore && idStore !== 'todos' ? idStore : ''}`;
      const {data} = await api.get(url);
      return {data, isError: false};
    } catch (err: any) {
      return {
        data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
        isError: true
      };
    }
  },

  async dashboardBreigadaVencimento(
    searchData: any,
    local: any,
    idStore: string | number | undefined,
  ) {
    try {
      let url = `dashboard/brigadaVencimento?data_inicio=${searchData.data_inicio}&data_fim=${searchData.data_fim}&local=${local && local !== 'Todos' ? local : ''}&id_loja=${idStore && idStore !== 'todos' ? idStore : ''}`;
      const {data} = await api.get(url);
      return {data, isError: false};
    } catch (err: any) {
      return {
        data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
        isError: true
      };
    }
  },

  async dashboardPresencaGondolas(
    idStore: string | number | undefined,
    initialDate: string,
    finalDate: string,
    local: any,
  ) {
    try {
      let url = `dashboard/presencaGondola?data_inicial=${initialDate}&data_final=${finalDate}&local=${local && local !== 'todos' ? local : ''}&id_loja=${idStore && idStore !== 'todos' ? idStore : ''}`;
      const {data} = await api.get(url);
      return {data, isError: false};
    } catch (err: any) {
      return {
        data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
        isError: true
      };
    }
  },

  async dashboardBrigadaPerildo(
    idStore: string | number | undefined,
    initialDate: string,
    finalDate: string,
    local: any,
  ) {
    try {
      let url = `dashboard/brigadaPeriodo?dt_inicio=${initialDate}&dt_fim=${finalDate}&local=${local && local !== 'todos' ? local : ''}&id_loja=${idStore && idStore !== 'todos' ? idStore : ''}`;
      const {data} = await api.get(url);
      return {data, isError: false};
    } catch (err: any) {
      return {
        data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
        isError: true
      };
    }
  },

  async saveObsBrigadaValidade(params: any){
    try{
      const { data } = await api.post('leitura-produtos/observacao', params);
      return {data, isError: false};
    } catch (err: any) {
      return {
        data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
        isError: true
      };
    }
  },

  async dashboardLeituras(
    idStore: string | number | undefined,
    initialDate: string,
    finalDate: string,
    local: any,
  ) {
    try {
     let url = `leituras?relations=coletor,loja,localizacao,tipoLeitura&whereDate=created_at,>=,${initialDate},created_at,<=,${finalDate}`;

     if (local.length){
       url += `&whereIn=localizacao_id,`+local
     }
     if (idStore){
       url += `&where=loja_id,=,`+idStore
     }


      const {data} = await api.get(url);
      return {data, isError: false};
    } catch (err: any) {
      return {
        data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
        isError: true
      };
    }
  },

  async dashboardGetLeiturasProdutos(
    leitura_id
  ) {
    try {
      let url = `leituras-produtos?relations=fotos,produto&where=leitura_id,=,${leitura_id}&order=id,asc`;
      const {data} = await api.get(url);
      return {data, isError: false};
    } catch (err: any) {
      return {
        data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
        isError: true
      };
    }
  },


};
