import {QueryParams, Usuario, changePassword, Departamento} from '../types';

import api, {getAll, create, update, getOne, deleteOne, RequestError, RequestResponse, listAll} from './api';

const endpoint = 'usuarios';
const relationship = 'roles,lojas,tiposLeitura';
export default {
  all: async (params?: QueryParams) => getAll<Usuario>({ endpoint, params }),
  listAll: async () => listAll<Departamento>({ endpoint }),
  one: async (id: string) => getOne<Usuario>({ endpoint, id, relationship }),
  destroy: async (id: string) => deleteOne({ endpoint, id }),
  create: async (formData: Partial<Usuario>) => create<Usuario>({ endpoint, formData }),
  update: async (id: string, formData: Partial<Usuario>) => update<Usuario>({ endpoint, formData, id }),
  async changePassword (formData: Partial<changePassword>): Promise<RequestError | RequestResponse> {
    try {
      const { data } = await api.post(endpoint+'/alterar-senha', formData);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err?.response?.data);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },
  async resetPassword (formData: any): Promise<RequestError | RequestResponse> {
    try {
      const { data } = await api.post(endpoint+'/resetar-senha', formData);
      return { data, isError: false };
    } catch (err: any) {
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },
  async resetAlterPassword (formData: any): Promise<RequestError | RequestResponse> {
    try {
      const { data } = await api.post(endpoint+'/reset-alterar-senha', formData);
      return { data, isError: false };
    } catch (err: any) {
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  }
};