import PageContainer from '../../../components/container/PageContainer';
import React, {useState} from 'react';
import ReactSelect from '../../../components/select/ReactSelect';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import Table from '../../../components/table/Table';
import useTable from '../../../hooks/useTable';
import taskApi from '../../../services/taskApi';
import Str from '../../../helpers/Str';

export default function TaskDashboard() {
  const [tipoTarefasSelected, setTipoTarefasSelected] = useState([]);
  const [dates, setDates] = useState({initial_date: '', final_date: ''})
  const [state, setState] = useState([])
  const [task, setTask]: any = useState({produtos:[]})
  const [tipoConsulta, setTipoConsulta] = useState('');
  const optionTarefas = [
    {label:'A fazer', value: 'a_fazer'},
    {label:'Realizado', value: 'realizado'},
    {label:'Atrazado', value: 'atrazado'},
  ]
  const openData = (dt: any) => {
    console.log(dt);
  }
  /*const { tableProps, loadData, onSearch } = useTable<any>({
    denyInitialLoad: true,
    dataLoader: async (params) => {
      console.log('aki')
      let where = '';
      if (dates.initial_date){
        where += `where=data,>,${dates.initial_date}`
      }
      if (dates.final_date){
        if (dates.initial_date){
          where += '&'
        }
        where += `where=data,<,${dates.final_date}`
      }
      // @ts-ignore
      params['where'] = where;
      if (params) {
        params['relations'] = 'produtos,conferente';
      }
      const { data, isError } = await taskApi.all(params);
      return isError ? false : data;
    },
    onItemClick: openData
  });*/


  const searchData = async () => {

    let params: any = []
    let where = '';
    if (tipoTarefasSelected['value']){
      if (tipoTarefasSelected['value'] === 'realizado'){
        where += 'where=status,=,1'
        setTipoConsulta('Realizado')
      }

      if (tipoTarefasSelected['value'] === 'atrazado'){
        where += 'where=status,=,0&where=data,>,'+new Date().toISOString().split("T")[0]
        setTipoConsulta('Atrazado')
      }

      if (tipoTarefasSelected['value'] === 'a_fazer'){
        where += 'where=status,=,0&where=data,<=,'+new Date().toISOString().split("T")[0]
        setTipoConsulta('A fazer')
      }
    }




    if (dates.initial_date){
      if (where !== ''){
        where += '&'
      }
      where += `where=data,>=,${dates.initial_date}`
    }
    if (dates.final_date){
      if (dates.initial_date){
        where += '&'
      }
      where += `where=data,<=,${dates.final_date}`
    }
    params['where'] = where;
    const {isError, data} = await taskApi.getDashboard(params);
    if (!isError){
      setState(data)
    }

  }

  const openProducts = (tarefa: any) => {
    setTask(tarefa)
  }

  return(
    <PageContainer menuTitle="Dashboard Tarefas">
      <div className={'p-5 bg-white border rounded-md flex-col'}>
        <div className={'grid grid-cols-12 gap-3'}>
          <ReactSelect
            className={'col-span-3'}
            label="Tarefas"
            required={false}
            options={optionTarefas}
            value={tipoTarefasSelected}
            setValue={v => {setTipoTarefasSelected(v)}}
          />
          <Input
            className={'col-span-2'}
            label={'Data Inicial'}
            value={dates.initial_date}
            type={'date'}
            setValue={(v) => setDates({...dates, ['initial_date']: v})}
          />
          <Input
            className={'col-span-2'}
            label={'Data Final'}
            value={dates.final_date}
            type={'date'}
            setValue={(v) => setDates({...dates, ['final_date']: v})}
          />
          <Button className={'col-span-1 h-[37.5px] mt-[1.5rem]'} onClick={searchData}>Buscar</Button>
        </div>

      </div>
      <div className="grid grid-cols-3 gap-2">
        <div className="flex flex-col gap-2">
          <div className={'p-2 border rounded-lg bg-background'}>
            <table className="bg-light border-b w-full">
              <thead className="bg-light border-b sticky top-0">
                <tr className={'border-t '}>
                  <th className={'text-start py-3 px-5 '}>ID</th>
                  <th className={'text-start py-3 px-5'}>Nome da tarefa</th>
                  <th className={'text-start py-3 px-5'}>Status</th>
                </tr>
              </thead>
              <tbody>
              {
                state ? state.map((st: any) => {

                  let tipo = '';

                  //const hj = new Date().toISOString().split("T")[0];
                  const hj = new Date();

                  const dataState = new Date(st.data)

                  if (st.status){
                    tipo = 'Realizado'
                  }

                  if (st.status === 0 ){
                    if (hj.getTime() > dataState.getTime()) {
                      tipo = 'Atrasado'
                    }
                    else {
                      tipo = 'A faze'
                    }

                  }



                  return(
                    <tr onClick={() => openProducts(st)} className={'border-t bg-white hover:bg-light-dark hover:cursor-pointer active:bg-primary transition'}>
                      <td className={'text-start py-3 px-5 '}>{st.id}</td>
                      <td className={'text-start py-3 px-5'}>{st.nome}</td>
                      <td className={'text-start py-3 px-5'}>{tipo}</td>
                    </tr>
                  )
                }) : ''
              }

              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col gap-2 col-span-2 ">

          {
            task.id ?
              <div className={'bg-white p-2 border rounded-lg'}>
                <div className={'grid grid-cols-3 gap-2'}>
                  <Input label={'Nome'} value={task.nome} disabled={true}/>
                  <Input label={'Data'} type={'date'} value={task.data} disabled={true}/>
                  <Input label={'Responsavel'} value={task.conferente.nome} disabled={true}/>
                </div>
                <div className={'font-medium text-[16px] mt-4 mb-1'}>
                  Produtos
                </div>
                <table className="bg-light border-b w-full">
                  <thead className="bg-light border-b sticky top-0">
                  <tr className={'border-t '}>
                    <th className={'text-start py-3 px-5 '}>ID</th>
                    <th className={'text-start py-3 px-5'}>Nome produto</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    task ? task?.produtos.map((st: any) => {
                      return(
                        <tr  className={'border-t bg-white hover:bg-light-dark hover:cursor-pointer active:bg-primary transition'}>
                          <td className={'text-start py-3 px-5 '}>{st.id}</td>
                          <td className={'text-start py-3 px-5'}>{st.descricao}</td>
                        </tr>
                      )
                    }) : ''
                  }
                  </tbody>
                </table>
              </div>
              :
              ''
          }

        </div>
      </div>
    </PageContainer>
  )
}