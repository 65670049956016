import PageContainer from '../../../components/container/PageContainer';
import React, {useEffect, useState} from 'react';
import Title from '../../../components/text/Title';
import PageCard from '../../../components/card/PageCard';
import Alert from '../../../helpers/Alert';
import lojaApi from '../../../services/lojaApi';
import localizacaoApi from '../../../services/localizacaoApi';
import Select from '../../../components/select/Select';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import dashboardApi from '../../../services/dashboardApi';
import Str from '../../../helpers/Str';
import {MdEdit, MdImageSearch} from 'react-icons/md';
import Modal from '../../../components/modal/Modal';
import useModal from '../../../hooks/useModal';
import CurrencyInput from 'react-currency-input-field';
import { FaRegTrashAlt } from "react-icons/fa";
import leiturasProdutosApi from '../../../services/leiturasProdutosApi';
import leituraApi from '../../../services/leituraApi';
import leituraProdutosFotosApi from '../../../services/leituraProdutosFotosApi';
import ReactSelect from '../../../components/select/ReactSelect';

export default function Searches() {
  const [selectStore, setSelectStore] = useState([]);
  const [searchDataSelect, setSearchDataSelect] = useState<any>('');

  const [selectLocalizacao, setSelectLocalizacao] = useState<any>([]);

  const [searchDataLocalizacao, setSearchDataLocalizacao] = useState<any>([{label:'Todos', value: ''}]);

  const [searchData, setSearchData] = useState<any>({
    'data_inicio': new Date().toISOString().split("T")[0],
    'data_fim': new Date().toISOString().split("T")[0],
  });
  const [searchLoad, setSearchLoad] = useState(false);
  const [data, setData] = useState<any>([]);
  const [leiturasProdutos, setLeiturasPradutos] = useState<any>([]);
  const [fotos, setFotos] = useState<any>([]);

  const { modalProps, open, close } = useModal();
  const modalPictures = useModal();

  async function loadData() {
    Alert.await(`Carregando dados...`);
    const {data, isError} = await lojaApi.listAll();
    if (isError) return alert(data.message || 'Falha ao carregar dados da loja.');
    let dtSelect = data.map((res: any) => ({label:res.razao_social, key: res.id}));
    dtSelect.unshift({label:'Todos', key: ''});
    setSelectStore(dtSelect as any);

    const dataLocalization = await localizacaoApi.listAll();
    if (dataLocalization.isError) return alert(dataLocalization.data.message || 'Falha ao carregar dadas localizações.');
    let dtlocSelect = dataLocalization.data.map((res: any) => ({label:res.descricao, value: res.id}));
    dtlocSelect.unshift({label:'Todos', value: ''});
    setSelectLocalizacao(dtlocSelect as any);
    Alert.close();
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleClick = async () => {
    if (!searchData.data_inicio || !searchData.data_fim) {
      setSearchLoad(false);
      return Alert.error('É necessário selecionar a data');
    }

    let dataLocalizacao = searchDataLocalizacao ? [...searchDataLocalizacao] : [];
    var indice = dataLocalizacao.indexOf("Todos");
    if (!(indice < 0)){
      dataLocalizacao.splice(indice, 1);
    }
    dataLocalizacao = dataLocalizacao.map(loc => loc.value);
    dataLocalizacao = dataLocalizacao.filter(x => x.length > 0);

    setSearchLoad(true);
    const {data, isError} = await dashboardApi.dashboardLeituras(
      searchDataSelect,
      searchData.data_inicio,
      searchData.data_fim,
      dataLocalizacao
    );
    if (!isError){
      setData(data);
    }
    setSearchLoad(false);
  }

  const openModal = async (leitura) => {

    const {data, isError} = await dashboardApi.dashboardGetLeiturasProdutos(leitura.id)
    setLeiturasPradutos(data)
    open()
  }

  const onChangeValueLeiturasProduto = (value, name, index) => {
    const newLeituras = [...leiturasProdutos];
    newLeituras[index][name] = value;
    setLeiturasPradutos(newLeituras);
  }

  const onEditaLeituraProdutos = async (leituraProduto) => {
    leituraProduto.preco_varejo = leituraProduto.preco_varejo ? parseFloat(leituraProduto.preco_varejo.replace(",", ".")) : null
    leituraProduto.preco_atacado = leituraProduto.preco_atacado ? parseFloat(leituraProduto.preco_atacado.replace(",", ".")) : null
    const {data, isError} = await leiturasProdutosApi.update(leituraProduto.id, leituraProduto);
    if(!isError){
      Alert.success('Produto editado com sucesso');
    }
  }

  const onDeleteLeituraProduto = async (leituraProduto) => {
    const confirm = await Alert.confirm(
      'Tem certeza que deseja excluir?',
      `Excluir Produto?`
    );
    if (! confirm) return;

    const {data, isError} = await leiturasProdutosApi.destroy(leituraProduto.id);
    if (!isError){
      const newLeiturasProdutos = [...leiturasProdutos];
      setLeiturasPradutos(newLeiturasProdutos.filter(leituraProd => leituraProd.id !== leituraProduto.id))
      Alert.success('Produto deletado com sucesso!');
    }
  }

  const onDeleteLeitura = async (leitura) => {
    const confirm = await Alert.confirm(
      'Tem certeza que deseja excluir?',
      `Excluir Leitura?`
    );
    if (! confirm) return;

    const leituraApiData = await leituraApi.destroy(leitura.id);
    if (!leituraApiData.isError){
      const newLeituras = [...data];
      setData(newLeituras.filter(leituraFilter => leituraFilter.id !== leitura.id))
      Alert.success('Leitura deletada com sucesso!');
    }

  }

  const openModalPictures = (fotos) => {
    setFotos(fotos);
    modalPictures.open()

  }

  const onDeleteFoto = async (foto) => {

    const confirm = await Alert.confirm(
      'Tem certeza que deseja excluir?',
      `Excluir Foto?`
    );
    if (!confirm) return;
    const leituraProdutosFotos = await leituraProdutosFotosApi.destroy(foto.id);
    let newFotos = [...fotos];

    newFotos = newFotos.filter(fotoData => foto.id != fotoData.id )

    const newLeituras = [...leiturasProdutos];
    const index = newLeituras.findIndex( x => x.id === foto.leitura_produto_id );
    newLeituras[index]['fotos'] = newFotos
    setLeiturasPradutos(newLeituras);
    setFotos(newFotos)
    Alert.success('Foto deletada com sucesso!');
  }

  return(
    <PageContainer menuTitle="Pesquisas" pageTitle="Pesquisas">
      <PageCard
        header={
          <div className="flex items-center justify-between">
            <Title color="primary">
              Pesquisas
            </Title>
          </div>
        }
      >
        <div className="grid grid-cols-12 gap-5 pb-7">
          <Select
            label="Seleciona loja"
            className="col-span-3"
            value={searchDataSelect}
            setValue={v => setSearchDataSelect(v)}
            items={selectStore}
          />
           <ReactSelect
             label="Seleciona localização"
             required={false}
             className="col-span-3"
             options={selectLocalizacao}
             value={searchDataLocalizacao}
             setValue={v => {
               setSearchDataLocalizacao(v);
             }}
             isMult={true}
           />

          <Input
            label="Data inicial"
            type={"date"}
            className="col-span-2"
            value={searchData.data_inicio}
            setValue={(v) => setSearchData({...searchData, ['data_inicio']: v})}
          />
          <Input
            label="Data final"
            type={"date"}
            className="col-span-2"
            value={searchData.data_fim}
            setValue={(v) => setSearchData({...searchData, ['data_fim']: v})}
          />

          <Button
            className="min-w-[147px] h-[37.5px] mt-[1.5rem]"
            onClick={() => handleClick()}
            loading={searchLoad}
          >
            Buscar dados
          </Button>
        </div>
        <div className={'flex-row'}>
          <div className={'pb-5 w-[100%]'}>
            {
              data.length ?
                <table className="table-auto w-[100%]">
                  <thead className="bg-light border-b">
                  <tr className={'border-t'}>
                    <th className={'text-start py-3 px-5'}>Loja</th>
                    <th className={'text-start py-3 px-5'}>Localização</th>
                    <th className={'text-start py-3 px-5'}>Coletor</th>
                    <th className={'text-start py-3 px-5'}>Data criação</th>
                    <th className={'text-start py-3 px-5'}>Data finalização</th>
                    <th className={'text-start py-3 px-5'}>Tipo de leitura</th>
                    <th className={'text-start py-3 px-5'}></th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    data.length ? data.map((dt: any, key) => {
                      return(
                        <>
                          <tr>
                            <td className="px-5">{dt.loja.razao_social}</td>
                            <td className="px-5">{dt.localizacao.descricao}</td>
                            <td className="px-5">{dt.coletor.nome}</td>
                            <td className="px-5">{Str.convertDateCreatedAt(dt.created_at)}</td>
                            <td className="px-5">{Str.convertDate(dt.dt_finalizacao)}</td>
                            <td className="px-5">{dt.tipo_leitura.descricao}</td>
                            <td className="px-5">
                              <div className={'flex gap-4'}>
                                <button onClick={() => openModal(dt)} title={"visualizar leituras"} className={' w-[35px] h-[35px]  flex justify-center items-center'}>
                                  <MdEdit className={'hover:text-gray-400'} size={20} />
                                </button>
                                <button >
                                  <FaRegTrashAlt onClick={() => onDeleteLeitura(dt)} size={20} />
                                </button>
                              </div>
                            </td>

                          </tr>
                        </>
                      )
                    }) : ''
                  }
                  </tbody>
                </table>
                :''
            }
          </div>
        </div>
      </PageCard>

      <Modal  title={"Leituras da pesquisa"} {...modalProps}>
        <table className="table-auto w-[100%]">
          <thead className="bg-light border-b">
          <th className={'text-start py-3 px-5'}>Produto</th>
          <th className={'text-start py-3 px-5'}>Ean</th>
          <th className={'text-start py-3 px-5'}>Lote</th>
          <th className={'text-start py-3 px-5'}>Quantidade</th>
          <th className={'text-start py-3 px-5'}>Validade</th>
          <th className={'text-start py-3 px-5'}>Preço varejo</th>
          <th className={'text-start py-3 px-5'}>Preço atacado</th>
          <th className={'text-start py-3 px-5'}>Observação</th>
          <th className={'text-start py-3 px-5'}></th>
          </thead>
          <tbody>
          {
            leiturasProdutos.length ?
              leiturasProdutos.map((leitura: any, key) => {
                return(
                  <tr>
                    <td className="p-1">{leitura.produto.descricao}</td>
                    <td className="p-1">{leitura.produto.ean}</td>
                    <td className="p-1">
                      <Input
                        setValue={v => onChangeValueLeiturasProduto(v, 'lote', key)}
                        value={leitura.lote}
                      />
                    </td>
                    <td className="p-1">
                      <Input
                        type={'number'}
                        setValue={v => onChangeValueLeiturasProduto(v, 'quantidade', key)}
                        value={leitura.quantidade}
                      />
                    </td>
                    <td className="p-1">
                      <Input
                        type={'date'}
                        value={leitura.validade}
                        setValue={v => onChangeValueLeiturasProduto(v, 'validade', key)}
                      />
                    </td>
                    <td className="p-1">
                      <CurrencyInput
                        decimalSeparator=","
                        groupSeparator="."
                        fixedDecimalLength={2}
                        className={'py-4 bg-light border border-border rounded-md outline-none px-3 lg:px-6 w-full focus:bg-background focus:border-primary transition'}
                        name="preco_varejo"
                        value={leitura.preco_varejo}
                        decimalScale={2}
                        decimalsLimit={2}
                        onValueChange={(value, name, values) => onChangeValueLeiturasProduto(value, name, key)}
                      />
                    </td>
                    <td className="p-1">
                      <CurrencyInput
                        decimalSeparator=","
                        groupSeparator="."
                        fixedDecimalLength={2}
                        className={'py-4 bg-light border border-border rounded-md outline-none px-3 lg:px-6 w-full focus:bg-background focus:border-primary transition'}
                        name="preco_atacado"
                        value={leitura.preco_atacado}
                        decimalScale={2}
                        decimalsLimit={2}
                        onValueChange={(value, name, values) => onChangeValueLeiturasProduto(value, name, key)}

                      />
                    </td>
                    <td className="p-1">
                        <textarea
                          onChange={v => onChangeValueLeiturasProduto(v.target.value, 'observacao', key)}
                        >
                          {leitura.observacao}
                        </textarea>
                    </td>

                    <td className="p-1">
                      <div className={'flex gap-4'}>
                        <button onClick={() => onEditaLeituraProdutos(leitura)} className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'} >
                          Salvar
                        </button>
                        <button onClick={() => onDeleteLeituraProduto(leitura)}>
                          <FaRegTrashAlt className={'hover:text-gray-400'} size={16} />
                        </button>
                        {
                          leitura.fotos.length ?
                            <button onClick={() => openModalPictures(leitura.fotos)}>
                              <MdImageSearch className={'hover:text-gray-400'} size={20} />
                            </button>
                            :
                            ''
                        }

                      </div>
                    </td>
                  </tr>
                )
              })

              :
              ''
          }
          </tbody>
        </table>
      </Modal>

      <Modal  title={"Fotos"} {...modalPictures.modalProps}>
        <div className="grid grid-cols-5 gap-4">
          {
            fotos.map(img => {
              return(
                <div className={'flex flex-col mb-5 mt-1'}>
                  <div >
                    <button onClick={() => onDeleteFoto(img)}>
                      <FaRegTrashAlt className={'hover:text-gray-400'}  size={20} />
                    </button>
                  </div>
                  <img
                    src={img.path}
                    className={'hover:cursor-pointer'}
                  />

                </div>

              )

            })
          }
        </div>
      </Modal>
    </PageContainer>
  )
}